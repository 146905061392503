<template>
  <div>
    <page-header icon="fa fa-dice" :title="title" :links="getLinks()"></page-header>
    <div class="sub-header">
      <b-row>
        <b-col>
          <entity-typeahead
            @change="onEntityChanged"
            @init="onEntityChanged"
            :initial-id="entityId"
          >
          </entity-typeahead>
        </b-col>
        <b-col cols="6" class="text-right">
          <tabs-bar
            :enabled="!isLoading(loadingName)"
            :tabs="tabs"
            :active="activeTab"
            @change="onTabChanged($event)"
          >
          </tabs-bar>
        </b-col>
      </b-row>
    </div>
    <div class="sub-header">
      <b-row>
        <b-col>
          <div v-if="selectedEntity">
            <b style="margin-right: 5px;">
              <a href @click.prevent="showEntitySidebar(selectedEntity)">
                {{ selectedEntity.members }}
              </a>
            </b>
            <span v-if="selectedEntityAdhesions.length">
              <span
                class="badge badge-dark"
                v-for="adhesion of selectedEntityAdhesions"
                :key="adhesion.id"
              >
                {{ adhesion.getLabel() }}
              </span>
            </span>
            <span
              class="badge badge-danger"
              v-else-if="selectedEntityType"
            >
              {{ selectedEntityType.name }}
            </span>
            <span v-else class="badge badge-danger">
              Non renseigné
            </span>
            &nbsp;
            <a class="btn btn-primary btn-xs" href @click.prevent="newAdhesion()" v-if="canAddAdhesion">
              <i class="fa fa-credit-card"></i> Nouvelle adhésion
            </a>
            <span v-if="selectedEntity">
              <span v-if="(depositCheckAmount > 0) && (entityDepositChecks.length === 0)" class="deposit-check-required">
                <add-store-deposit-check
                  :entity="selectedEntity"
                  :amount="depositCheckAmount"
                  @done="loadEntityInfo()"
                  text="Chèque de caution manquant"
                >
                </add-store-deposit-check>
              </span>
            </span>
            <span v-for="depositCheck of entityDepositChecks" :key="depositCheck.id">
              <store-deposit-check-badge :deposit-check="depositCheck" @done="loadEntityInfo()">
              </store-deposit-check-badge>
            </span>
          </div>
          <div v-else>
            <div class="empty-text">
              Sélectionnez une famille ci-dessus pour gérer ses emprunts et adhésions
            </div>
          </div>
        </b-col>
        <b-col cols="4" class="text-right">
          <tabs-bar
            v-if="showAdhesions"
            :enabled="!isLoading(loadingName)"
            :tabs="adhesionTabs"
            :active="activeAdhesionTab"
            @change="onAdhesionTabChanged($event)"
          >
          </tabs-bar>
          <span
            v-if="selectedEntity && showBorrowings && borrowingItems.length > 0"
            style="display: inline-block; margin-right: 5px;"
          >
            <a class="btn btn-primary" href @click.prevent="showReturnAll">
              <i class="fa fa-rotate-left"></i> Tout rendre
            </a>
          </span>
          <tabs-bar
            inline
            v-if="showBorrowings"
            :enabled="!isLoading(loadingName)"
            :tabs="borrowingTabs"
            :active="activeBorrowingTab"
            @change="onBorrowingTabChanged($event)"
          >
          </tabs-bar>
        </b-col>
      </b-row>
    </div>
    <div class="sub-header" v-if="showArticles">
      <b-row>
        <b-col cols="5">
          <b-form @submit.prevent="onSearch">
            <b-input-group>
              <b-form-input
                type="search"
                placeholder="Rechercher un jeu par son nom"
                v-model="searchText"
                autocomplete="off"
                :class="{ filled: searchedText }"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary">
                  <i class="fa fa-search"></i>
                </b-button>
                <b-button type="submit" variant="secondary" @click.prevent="resetSearchArticle()">
                  <i class="fa fa-close"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
        <b-col cols="2">
          <a class="btn btn-primary btn-block" href @click.prevent="loadFullList()">
            <i class="fa fa-puzzle-piece"></i> Liste complète
          </a>
        </b-col>
        <b-col cols="2">
          <a class="btn btn-primary btn-block" href @click.prevent="showInventory()">
            <i class="fa fa-list-check"></i> Inventaire
          </a>
        </b-col>
        <b-col cols="3">
          <div v-if="showArticles">
            <b-select v-model="selectedArticleType" @change="articleTypeChanged()">
              <b-select-option v-for="type of articleTypes" :key="type.id" :value="type">
                {{ type.name }}
              </b-select-option>
            </b-select>
          </div>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe" v-if="!isLoading(loadingName)">
      <b-row>
        <b-col>
          <div v-if="showArticles">
            <div ref="excelMe">
              <x-table
                id="articles"
                :columns="columns"
                :items="items"
                :show-counter="loaded"
                :verbose-name="'article' + articleCountLabel"
                :verbose-name-plural="'articles' + articleCountLabel"
              >
              </x-table>
            </div>
          </div>
          <div v-if="activeTab && activeTab.name === 'borrowings'">
            <div ref="excelMe">
              <x-table
                id="borrowings"
                :columns="borrowingColumns"
                :items="borrowingItems"
                show-counter
                :verbose-name="'emprunt' + (this.selectedEntity ? ' pour cette famille' : ' au total')"
                :verbose-name-plural="'emprunts' + (this.selectedEntity ? ' pour cette famille' : ' au total')"
              >
              </x-table>
            </div>
          </div>
          <div v-if="activeTab && activeTab.name === 'adhesions'">
            <div ref="excelMe">
              <x-table
                id="adhesions"
                :columns="adhesionColumns"
                :items="adhesionItems"
                show-counter
                :verbose-name="'adhésion' + (this.selectedEntity ? ' pour cette famille' : ' au total')"
                :verbose-name-plural="'adhésions'+ (this.selectedEntity ? ' pour cette famille' : ' au total')"
              >
              </x-table>
            </div>
          </div>
        </b-col>
        <b-col md="4" v-if="showAdhesions" class="text-right">
        </b-col>
        <b-col md="2" v-if="!showAdhesions">
          <always-top @done="onAlwaysTopRecalc1()" :recalc="recalcAlwaysTop1" height></always-top>
          <div v-if="selectedArticle" class="article-box">
            <article-info :article="selectedArticle" link></article-info>
          </div>
        </b-col>
        <b-col md="2" v-if="!showAdhesions">
          <always-top @done="onAlwaysTopRecalc2()" :recalc="recalcAlwaysTop2" height></always-top>
          <div v-if="selectedArticle" class="article-box">
            <article-items-info
              :article="selectedArticle"
              :entity="selectedEntity"
              :entity-types="entityTypes"
              :adhesion="firstAdhesion"
              :initial-entity-type="selectedEntityType"
              :can-borrow="true"
              @reload="onBorrowingChanged()"
            >
            </article-items-info>
          </div>
        </b-col>
      </b-row>
    </div>
    <return-all-borrow-modal
      v-if="selectedEntity"
      :entity="selectedEntity"
      :borrowings="entityBorrowings"
      :modal-id="returnAllModalId"
      @done="onBorrowingChanged()"
    >
    </return-all-borrow-modal>
    <store-adhesion-modal
      :entity="selectedEntity"
      :modal-id="storeAdhesionModalId"
      @done="onAdhesionChanged()"
    ></store-adhesion-modal>
    <confirm-modal
      name="cancel-store-adhesion"
      :object="selectedAdhesion"
      title="Annuler une adhésion"
      :text="getCancelAdhesionText()"
      @confirmed="onConfirmCancelAdhesion"
    >
    </confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import XTable from '@/components/Controls/Table/Table'
import PageHeader from '@/components/Layout/PageHeader'
import ArticleInfo from '@/components/Store/ArticleInfo'
import ArticleItemsInfo from '@/components/Store/ArticleItemsInfo'
import AddStoreDepositCheck from '@/components/Store/AddStoreDepositCheck.vue'
import StoreDepositCheckBadge from '@/components/Store/StoreDepositCheckBadge.vue'
import { BackendMixin } from '@/mixins/backend'
import {
  makeStoreArticle, makeBorrowing, makeStoreArticleType, makeStoreAdhesion, makeStoreEntityType, makeStoreDepositCheck
} from '@/types/store'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import router from '@/router'
import { TabItem } from '@/types/tabs'
import TabsBar from '@/components/Controls/TabsBar.vue'
import { dateToString } from '@/filters/texts'
import AlwaysTop from '@/components/Layout/AlwaysTop.vue'
import ReturnAllBorrowModal from '@/components/Store/ReturnAllBorrowModal.vue'
import EntityTypeahead from '@/components/Entity/EntityTypeahead.vue'
import StoreAdhesionModal from '@/components/Store/StoreAdhesionModal.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'

export default {
  name: 'Store',
  mixins: [BackendMixin],
  components: {
    StoreDepositCheckBadge,
    AddStoreDepositCheck,
    ConfirmModal,
    StoreAdhesionModal,
    EntityTypeahead,
    ReturnAllBorrowModal,
    AlwaysTop,
    TabsBar,
    ArticleInfo,
    ArticleItemsInfo,
    PageHeader,
    LoadingGif,
    XTable,
  },
  data() {
    return {
      loadingName: 'store-articles',
      articles: [],
      columns: [],
      borrowingColumns: [],
      adhesionColumns: [],
      searchText: '',
      searchedText: '',
      selectedArticle: null,
      selectedEntity: null,
      entityId: 0,
      tabs: [
        new TabItem('articles', 'Jeux', 'fa fa-puzzle-piece'),
        new TabItem('borrowings', 'Emprunts', 'fa fa-calendar-check'),
        new TabItem('adhesions', 'Adhésions', 'fa fa-id-card')
      ],
      activeTab: null,
      adhesionTabs: [
        new TabItem('current', 'En cours', 'fa fa-id-card'),
        new TabItem('archives', 'Archives', 'fas fa-box-archive')
      ],
      activeAdhesionTab: null,
      borrowingTabs: [
        new TabItem('current', 'En cours', 'fa fa-calendar-check'),
        new TabItem('today', 'À rendre', 'fa fa-calendar-day'),
        new TabItem('late', 'En retard', 'fa fa-clock')
      ],
      activeBorrowingTab: null,
      borrowings: [],
      adhesions: [],
      recalcAlwaysTop1: false,
      recalcAlwaysTop2: false,
      selectedArticleType: null,
      articleTypes: [],
      entityTypes: [],
      selectedEntityType: null,
      selectedEntityAdhesions: [],
      entityDepositChecks: [],
      isFamily: false,
      returnAllModalId: 'bv-modal-return-all-borrow-article',
      storeAdhesionModalId: 'bv-modal-create-store-adhesion-modal',
      loaded: false,
      selectedAdhesion: null,
      cancelAdhesionModalId: 'cancel-store-adhesion',
    }
  },
  mounted() {
    this.columns = this.getColumns()
    this.borrowingColumns = this.getBorrowingColumns()
    this.adhesionColumns = this.getAdhesionColumns()
    this.loadArticleTypes()
    this.loadEntityTypes()
    this.activeTab = this.tabs[0]
  },
  watch: {
  },
  computed: {
    canAddAdhesion() {
      return this.hasPerm('store.add_storeadhesion')
    },
    articleCountLabel() {
      let label = ''
      if (this.searchedText) {
        label = ' correspondant à ' + this.searchedText
      }
      if (this.selectedArticleType && this.selectedArticleType.id) {
        label += ' de type ' + this.selectedArticleType.name
      }
      if (!label) {
        label = ' au total'
      }
      return label
    },
    title() {
      return 'Ludothèque'
    },
    firstAdhesion() {
      if (this.selectedEntityAdhesions.length) {
        return this.selectedEntityAdhesions[0]
      }
      return null
    },
    items() {
      return this.articles.map(
        elt => this.makeItem(elt)
      )
    },
    entityBorrowings() {
      if (this.selectedEntity) {
        return this.borrowings.filter(elt => elt.entity.id === this.selectedEntity.id)
      }
      return []
    },
    borrowingItems() {
      let borrowings = this.borrowings
      if (this.selectedEntity) {
        borrowings = this.entityBorrowings
      }
      if (this.activeBorrowingTab.name === 'late') {
        borrowings = borrowings.filter(elt => elt.isLate())
      }
      if (this.activeBorrowingTab.name === 'today') {
        borrowings = borrowings.filter(elt => elt.isExpectedToday() || elt.isLate())
      }
      return borrowings.map(elt => this.makeBorrowingItem(elt))
    },
    entityAdhesions() {
      if (this.selectedEntity) {
        return this.adhesions.filter(elt => elt.entity.id === this.selectedEntity.id)
      }
      return []
    },
    adhesionItems() {
      let adhesions = this.adhesions
      if (this.selectedEntity) {
        adhesions = this.entityAdhesions
      }
      return adhesions.map(
        elt => this.makeAdhesionItem(elt)
      )
    },
    showArticles() {
      return (this.activeTab && this.activeTab.name === 'articles')
    },
    showBorrowings() {
      return (this.activeTab && this.activeTab.name === 'borrowings')
    },
    showAdhesions() {
      return (this.activeTab && this.activeTab.name === 'adhesions')
    },
    depositCheckAmount() {
      if (this.selectedEntity) {
        if (this.selectedEntityType) {
          return this.selectedEntityType.depositCheckAmount
        }
        for (const entityAdhesion of this.selectedEntityAdhesions) {
          if (entityAdhesion.adhesionType.entityType.depositCheckAmount > 0) {
            return entityAdhesion.adhesionType.entityType.depositCheckAmount
          }
        }
      }
      return 0
    },
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    getColumns() {
      const columns = [
        {
          name: 'name',
          label: 'Nom',
          onClick: item => {
            this.viewArticle(item.article)
          },
          isLink: item => {
            return true
          },
        },
        {
          name: 'type',
          label: 'Type',
        },
        {
          name: 'location',
          label: 'Emplacement',
        },
        {
          name: 'reference',
          label: 'Référence',
        }
      ]
      return columns
    },
    getBorrowingColumns() {
      const columns = [
        {
          name: 'entityName',
          label: 'Famille',
          onClick: item => {
            this.viewEntity(item.entity)
          },
          isLink: item => {
            return true
          },
        },
        {
          name: 'name',
          label: 'Article',
          onClick: item => {
            this.viewArticle(item.article)
          },
          isLink: item => {
            return true
          },
        },
        {
          name: 'reference',
          label: 'Référence',
        },
        {
          name: 'borrowedOn',
          label: 'Emprunté le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'expectedOn',
          label: 'Attendu le',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'status',
          label: 'Stade',
        }
      ]
      return columns
    },
    getAdhesionColumns() {
      const columns = [
        {
          name: 'entityName',
          label: 'Famille',
          onClick: item => {
            this.viewEntity(item.entity)
          },
          isLink: item => {
            return true
          },
        },
        {
          name: 'adhesionName',
          label: 'Type',
        },
        {
          name: 'validTo',
          label: 'Valide jusqu\'au',
          dateFormat: 'DD/MM/YYYY',
        },
        {
          name: 'limit',
          label: '',
        }
      ]
      const archives = (this.activeAdhesionTab && (this.activeAdhesionTab.name === 'archives'))
      if (!archives && this.canAddAdhesion) {
        columns.push(
          {
            name: 'cancel',
            label: '',
            onClick: item => {
              this.cancelAdhesion(item)
            },
            isLink: item => {
              return true
            },
            hideFilter: true,
          }
        )
      }
      return columns
    },
    getCancelAdhesionText() {
      if (this.selectedAdhesion) {
        return 'Annuler ' + this.selectedAdhesion.adhesionType.name + ' ' + this.selectedAdhesion.entity.name + '?'
      }
      return ''
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'liste-' + slugify('Ludothèque')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'liste-' + slugify('Ludothèque')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    addArticle() {
      router.push({ name: 'store-add-article', params: { }, })
    },
    getLinks() {
      let links = [
        {
          id: 1,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'far fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
      if (this.showArticles) {
        links.push(
          {
            id: 3,
            label: 'Ajouter un jeu',
            callback: this.addArticle,
            icon: 'fa fa-plus',
            cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
          }
        )
      }
      return links
    },
    recalcAlwaysTop() {
      this.recalcAlwaysTop1 = false
      this.recalcAlwaysTop2 = false
      const that = this
      setTimeout(
        function() {
          that.recalcAlwaysTop1 = true
          that.recalcAlwaysTop2 = true
        },
        200
      )
    },
    async loadArticleTypes() {
      const backendApi = new BackendApi('get', '/store/api/types/')
      try {
        let resp = await backendApi.callApi()
        this.articleTypes = [makeStoreArticleType({ name: 'Tous', })].concat(resp.data.map(elt => makeStoreArticleType(elt)))
        this.selectedArticleType = this.articleTypes[0]
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadEntityTypes() {
      const backendApi = new BackendApi('get', '/store/api/entity-types/')
      try {
        let resp = await backendApi.callApi()
        this.entityTypes = resp.data.map(elt => makeStoreEntityType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    loadFullList() {
      this.searchText = ''
      this.selectedArticleType = this.articleTypes[0]
      this.loadList()
    },
    showInventory() {
      router.push({ name: 'store-items', params: { }, })
    },
    articleTypeChanged() {
      if (this.searchText) {
        this.onSearch()
      } else {
        this.loadList()
      }
    },
    async loadList() {
      this.startLoading(this.loadingName)
      this.searchText = ''
      this.searchedText = ''
      this.selectedArticle = null
      let url = '/store/api/articles/'
      if (this.selectedArticleType && this.selectedArticleType.id) {
        url += '?type=' + this.selectedArticleType.id
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.articles = resp.data.map(elt => makeStoreArticle(elt))
        this.loaded = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
      this.recalcAlwaysTop()
    },
    async loadEntityInfo() {
      this.selectedEntityType = null
      this.isFamily = false
      this.selectedEntityAdhesions = []
      if (this.selectedEntity) {
        this.startLoading(this.loadingName)
        let url = '/store/api/entity-info/' + this.selectedEntity.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.isFamily = !!resp.data['is_family']
          if (resp.data['entity_type']) {
            this.selectedEntityType = makeStoreEntityType(resp.data['entity_type'])
          }
          if (resp.data['current_adhesions']) {
            this.selectedEntityAdhesions = resp.data['current_adhesions'].map(elt => makeStoreAdhesion(elt))
          }
          this.entityDepositChecks = resp.data['deposit_checks'].map(makeStoreDepositCheck)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onBorrowingChanged() {
      if (this.activeTab && this.activeTab.name === 'borrowings') {
        this.loadBorrowingList(true)
      }
      this.loadEntityInfo()
    },
    onAdhesionChanged() {
      if (this.activeTab && this.activeTab.name === 'adhesions') {
        this.loadAdhesionsList()
      }
      this.loadEntityInfo()
    },
    async loadBorrowingList(keep = false) {
      this.startLoading(this.loadingName)
      if (!keep) {
        this.selectedArticle = null
      }
      let url = '/store/api/current-borrowings/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.borrowings = resp.data.map(elt => makeBorrowing(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
      this.recalcAlwaysTop()
    },
    async loadAdhesionsList() {
      this.startLoading(this.loadingName)
      this.selectedArticle = null
      const archive = (this.activeAdhesionTab.name === 'archives')
      let url = '/store/api/adhesions/?'
      if (archive) {
        url += '&archive=1'
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.adhesions = resp.data.map(elt => makeStoreAdhesion(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async onSearch() {
      if (this.searchText) {
        this.selectedArticle = null
        this.searchedText = ''
        this.startLoading(this.loadingName)
        let url = '/store/api/articles/search/?search=' + this.searchText
        if (this.selectedArticleType && this.selectedArticleType.id) {
          url += '&type=' + this.selectedArticleType.id
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.articles = resp.data.articles.map(elt => makeStoreArticle(elt))
          this.loaded = true
          if (this.articles.length === 1) {
            this.viewArticle(this.articles[0])
          }
          this.searchedText = this.searchText
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        await this.loadList()
      }
    },
    resetSearchArticle() {
      this.searchText = ''
      this.onSearch()
    },
    makeItem(elt) {
      return {
        name: elt.name,
        type: elt.type.name,
        location: elt.location.name,
        article: elt,
        reference: elt.items.map(itm => itm.reference).join(', '),
      }
    },
    makeAdhesionItem(elt) {
      return {
        entityName: elt.entity.name,
        entity: elt.entity,
        adhesionName: elt.adhesionType.name,
        validTo: elt.validTo ? dateToString(elt.validTo, 'YYYY-MM-DD') : '',
        limit: elt.limit(),
        cancel: '<i class="fa fa-close" title="Annuler"></i>',
        adhesion: elt,
      }
    },
    makeBorrowingItem(elt) {
      let cssClass = ''
      if (elt.hasPenalties()) {
        cssClass = 'danger'
      } else if (elt.isLate()) {
        cssClass = 'warning'
      } else if (elt.isExpectedToday()) {
        cssClass = 'highlight'
      }
      return {
        name: elt.article.name,
        reference: elt.item.reference,
        entityName: elt.entity.name,
        entity: elt.entity,
        article: elt.article,
        borrowedOn: dateToString(elt.borrowedOn, 'YYYY-MM-DD'),
        expectedOn: dateToString(elt.returnExpectedOn, 'YYYY-MM-DD'),
        status: elt.getStatus(),
        cssClass: cssClass,
      }
    },
    viewArticle(article) {
      this.selectedArticle = article
    },
    viewEntity(entity) {
      if (entity) {
        this.entityId = entity.id
      } else {
        this.entityId = 0
      }
      this.selectedArticle = null
    },
    onTabChanged(tab) {
      this.activeTab = tab
      if (this.activeTab.name === 'articles') {
        this.selectedArticle = null
      }
      if (this.activeTab.name === 'borrowings') {
        this.onBorrowingTabChanged(this.borrowingTabs[0])
      }
      if (this.activeTab.name === 'adhesions') {
        if (this.activeAdhesionTab === null) {
          this.onAdhesionTabChanged(this.adhesionTabs[0])
        }
      }
    },
    onAdhesionTabChanged(tab) {
      this.activeAdhesionTab = tab
      this.adhesionColumns = this.getAdhesionColumns()
      this.loadAdhesionsList()
    },
    onBorrowingTabChanged(tab) {
      this.activeBorrowingTab = tab
      this.loadBorrowingList()
    },
    onAlwaysTopRecalc1() {
      this.recalcAlwaysTop1 = false
    },
    onAlwaysTopRecalc2() {
      this.recalcAlwaysTop2 = false
    },
    showReturnAll() {
      this.$bvModal.show(this.returnAllModalId)
    },
    newAdhesion() {
      this.$bvModal.show(this.storeAdhesionModalId)
    },
    onEntityChanged(event) {
      if (event.entity) {
        this.selectedEntity = event.entity
      } else {
        this.selectedEntity = null
      }
      this.loadEntityInfo()
    },
    cancelAdhesion(item) {
      this.selectedAdhesion = item.adhesion
      this.$bvModal.show('bv-confirm-modal:' + this.cancelAdhesionModalId)
    },
    async onConfirmCancelAdhesion() {
      if (this.selectedAdhesion) {
        let url = '/store/api/adhesions/' + this.selectedAdhesion.id + '/cancel/'
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi()
          await this.loadAdhesionsList()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
</style>
